import React from "react";
import { Col, Row } from "react-bootstrap";
import { FiArrowLeft } from "react-icons/fi";

import styles from "./index.module.sass";

const BackArrowTitle = ({ title }) => (
  <Row className={styles.container}>
    <Col xs={12} sm={12} md={4} lg={4} className={styles.return}>
      <a href="/">
        <FiArrowLeft className={styles.icon} />
      </a>
    </Col>
    <Col xs={12} sm={12} md={4} lg={4} className={styles.title}>
      <h1>{title}</h1>
    </Col>
  </Row>
);

export default BackArrowTitle;
