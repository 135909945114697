import React from 'react';
import styles from './css/index.module.sass';
import Layout from '../components/layout';
import SEO from '../components/seo';
import JoinUs from '../components/JoinUs';

const RecruitmentPage = () => (
  <Layout>
    <SEO title="Recruitment" />
    <div className={styles.paragraphs}>
      <JoinUs />
    </div>
  </Layout>
);

export default RecruitmentPage;
