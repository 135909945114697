import React from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { FaDownload } from 'react-icons/fa';

import styles from './index.module.sass';
import BackArrowTitle from '../Common/BackArrowTitle';

import joinus1 from '../../images/join-us/joinus1.jpeg';
import recruitment from '../../files/recruitment.pdf';

const JoinUs = () => (
  <React.Fragment>
    <BackArrowTitle title="Recrutement" />
    <Row className={styles.container}>
      <Col xs={12} md={12} lg={2}></Col>
      <Col xs={12} md={12} lg={8} className={styles.recruitment}>
        <img src={joinus1} />
        <a href={recruitment} download>
          <Button>
            Télécharger les conditions <FaDownload />
          </Button>
        </a>
      </Col>
    </Row>
  </React.Fragment>
);

export default JoinUs;
